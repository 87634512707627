<template>
  <div class="outter">
    <div class="ad_banner">
      <img src="../assets/image/page1/coreService.png" alt="">
    </div>
    <div class="core">
      <div class="core-pay container" id="part1">
        <div class="title_light">
          <img src="../assets/image/page1/title-pay.png" alt="">
        </div>
        <div class="main1 clearFix">
          <div class="pay-service">
            <img src="../assets/image/page1/payWay.png" alt="">
          </div>
          <div class="fr pay-service-content">
            <span>服务化理赔 创造全新体验</span>
            <p>拥有药联健康权益的消费者到药联合作药店及健康服务机构可直接使用药联健康权益支付药品或健康服务，无需后续提交发票报销等操作，为权益保障用户提供优质便捷的服务。</p>

            <span>闭环系统 确保合规控费</span>
            <p>目前该服务已与人保财险、人保寿险、太平洋保险、国寿、华夏保险等国內主要保险公司均建立了合作，在全国有超过10万余家药店以及众多体检机构、中医馆、网上药店等均可使用药联直付。药联开创性的实现了与所有合作药店及服务机构技术系统底层对接互通，前端交易在药联闭环系统中进行，实现了保障权益的规则管理和事前风控，解决了在消费场景下的控费合规的要求系统具备完全自主知识产权。</p>

            <span>全国覆盖 服务丰富</span>
            <p>截至2019年底，单月交易金额已过亿元。药联合作的药店覆盖全国主要省市，与国大、一心堂、益丰、老百姓、大森林、海王星辰、漱玉、益康等国内百强连锁均建立了合作。药店总量截至2019年底为8万余家，2020年底预计突破15万家门店。同时，药联直付服务还整合了齿科、体检、中医馆等非药健康服务，为商保用户提供丰富的服务选择。</p>
          </div>
          <div class="pay-service-bg"></div>
        </div>
      </div>
      <div class="core-value" id="part2">
        <div class="container">
          <div class="title_light">
            <img src="../assets/image/page1/core-tit.png" alt="">
          </div>
          <div class="mini-word">药联增值服务是药联健康联合大型保险公司针对购药等健康消费需求下的不同年龄人群、特定慢病人群等推出的健康增值服务产品</div>
          <div class="mini-word padding">为消费者在健康消费及健康保障方面提供整体解决方案，降低消费者用药成本，提高风险抵抗能力</div>
          <ul class="clearFix">
            <li v-for="(value, index) in valueService" :key="index" class="core-value-service fl">
              <img :src="value.src" alt="" class="fl">
              <div class="core-value-service-word fl">
                <div>{{value.title}}</div>
                <div>{{value.include}}</div>
                <div>{{value.exchange1}}</div>
                <div v-show="value.exchange2">{{value.exchange2}}</div>
              </div>
            </li>
          </ul>
          <ul class="clearFix padding">
            <li v-for="(value, index) in valueServiceTwo" :key="index" class="core-value-service fl">
              <img :src="value.src" alt="" class="fl">
              <div class="core-value-service-word fl">
                <div>{{value.title}}</div>
                <div>{{value.include}}</div>
                <div>{{value.exchange1}}</div>
                <div v-show="value.exchange2">{{value.exchange2}}</div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div style="width: 100%;height: 333px"></div>
      <div class="core-operate" id="part3">
        <div class="container">
          <div class="title_light">
            <img src="../assets/image/page1/core-operate.png" alt="">
          </div>
          <ul>
            <li v-for="(item, index) in operate" :key="index">
              <img class="bgSrc" :src="item.bgSrc" alt="">
              <img class="iconSrc" :src="item.iconSrc" alt="">
              <div class="core-operate-title">{{item.title}}</div>
              <div class="core-operate-txt">{{item.txt}}</div>    
            </li>
          </ul>
        </div>
      </div>
      <div class="core-union" id="part4">
        <div class="container">
          <div class="title_light">
            <img src="../assets/image/page1/core-union.png" alt="">
          </div>
          <ul>
            <li v-for="(un, index) in union" :key="index">
              <div class="title">{{un.title}}</div>
              <div class="line"></div>
              <div class="txt">{{un.txt}}</div>
            </li>
          </ul>
        </div>
      </div>
      <div style="width: 100%;height: 236px"></div>
      <div class="core-project">
        <div class="container">
          <div class="title_light">
            <img src="../assets/image/page1/core-project.png" alt="">
            <div class="mini">助力药店成长 构建商保网络</div>
          </div>
          <img class="imgbg" src="../assets/image/page1/projectbg.png" alt="">
          <ul>
            <li>
              <div class="word-weight">补贴资源（普惠1.0）</div>
              <div class="word-article">提升本店转化、提升客单价提高利润额</div>
            </li>
            <li>
              <div class="word-weight">店员会员运营工具</div>
              <div class="word-article">凸显店员持续服务价值建立私域会员流量池</div>
            </li>
            <li>
              <div class="word-weight">O2O交易工具</div>
              <div class="word-article">满足会员送药上门需求店外获客转化</div>
            </li>
            <li>
              <div class="word-weight">会员主题套餐工具</div>
              <div class="word-article">定制增值服务、锁定长期消费整合医疗健康服务凸显连锁差异化价值</div>
            </li>
          </ul>
          <div class="change">一切都在改变&nbsp;你该如何改变？药联不开药店&nbsp;商保助力药店</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      valueService: [
        {
          src: require('../assets/image/page1/value1.png'),
          title: '药品过期免费换新',
          include: '每单最多可保6盒药品',
          exchange1: '每单最多可保200元药品'
        },
        {
          src: require('../assets/image/page1/value2.png'),
          title: '糖尿病关爱服务',
          include: '含650直付权益',
          exchange1: '提供最高5万元糖尿病',
          exchange2: '并发症保障'
        },
        {
          src: require('../assets/image/page1/value3.png'),
          title: '臻选体检健康关爱服务',
          include: '含600直付权益',
          exchange1: '价值443基础体检',
          exchange2: '提供最高900元120急救补贴'
        }
      ],
      valueServiceTwo: [
        {
          src: require('../assets/image/page1/value4.png'),
          title: '惠民特药关爱服务',
          include: '含35购药权益',
          exchange1: '提供最高900元120急救补贴'
        },
        {
          src: require('../assets/image/page1/value5.png'),
          title: '亲密无忧健康关爱服务',
          include: '含139.8金毓婷直付权益',
          exchange1: '提供最高600元意外怀孕保障金'
        },
      ],
      operate: [
        {
          bgSrc: require('../assets/image/page1/oper1.png'),
          iconSrc: require('../assets/image/page1/oper11.png'),
          title: '店内高效转化',
          txt: '营销活动助力，优惠直达顾客。店员操作简单明了，顾客参与无门槛，牢牢锁定现有客流，维系顾客忠诚度，有效提升客单价。'
        },
        {
          bgSrc: require('../assets/image/page1/oper2.png'),
          iconSrc: require('../assets/image/page1/oper22.png'),
          title: '创新营销工具 助力店外获客',
          txt: '药联免费互联网营销工具，解决门店无法触达潜在客流痛点，动动手指就能转发，轻松拉来新顾客，店外顾客转化为进店客流。'
        },
        {
          bgSrc: require('../assets/image/page1/oper3.png'),
          iconSrc: require('../assets/image/page1/oper33.png'),
          title: '持续服务 智能数据支持',
          txt: '药联智盈后台，7X24全方位监控业务经营数据，了解药联大盘数据，知晓隔壁竞争对手情况，大数据时代一手掌握信息。'
        },
      ],
      union: [
        {
          title: '营销工具支持',
          txt: '基于药联数万家合作药店及几十万店员人群、以及精准大数据匹配用户人群，和药联专业的互联网运营优势，创新使用一些用户裂变工具（比如健康裂变红包等）对用户进行精准裂变拉新，并使用药联的权益和服务对用户进行留存，联合合作伙伴进行各种活动，对用户进行促活，最终以药联权益和服务的本质核心（更实惠、更便捷、更安全、更有效）对用户进行高效转化，此方式也用于帮助拉回“脱保”用户、裂变新车险用户、提升留存和提升转化。'
        },
        {
          title: '产品组合创新',
          txt: '药联联合合作保险公司共同针对特定人群特定保障及消费需求，通过直付权益与保险公司已有的风险保障产品的组合，实现针对特定人群场景化的需求满足，进一步可支持基于大数据的用户画像特定个体、特定产品的服务，实现千人千面。目前针对特定年龄人群、特定慢病人群等已开创出部分增值服务产品，在渠道取得了喜人的效果。'
        },
        {
          title: '数据智能支撑',
          txt: '药联用户大数据，根据地区、年龄、性别、职业、车主、高血压、心脑血管疾病、糖尿病、各种病症、购药习惯、保健习惯、需求健康知识、兴趣爱好、社群群体、活动喜好、身体健康数据、病症周期、服药周期、基因检测数据、咨询交互数据等等多维度交叉矩阵属性，在最细的颗粒度上构建用户画像，精准匹配业务场景和服务，并通过药联内部算法动态计算价值等级划分的精准用户画像。'
        }
      ]
    }
  },
  created() {},
  mounted() {
    if (this.$route.hash) {
      document.querySelector(this.$route.hash).scrollIntoView(true)
    }
  },
  methods: {}
}
</script>
<style lang="less" scoped>
@import '../assets/css/mixin.less';
.text1 {
  text-align: center;
  font-size: 12px;
  color: #454545;
  margin: 24px 0 41px 0;
  line-height: 27px;
}

.core {
  overflow: hidden;
  
  .core-pay {
    padding: 70px 0;

    .title_light img {
      .wh(309px, 64px);
    }
  }

  .core-value {
    background-color: #EBF9F7;
    padding: 68px 0 0;
    height: 350px;
    box-sizing: border-box;

    .title_light {
      margin: 0 0 20px;

      img {
        .wh(389px, 64px);
      }
    }

    .mini-word {
      .font(16px, #666);
      line-height: 26px;
      text-align: center;

      &.padding{
        padding: 0 0 44px;
      }
    }

    ul {
      padding: 0 0 34px;
    }

    ul.padding {
      padding: 0 0 0 205px;

      .core-value-service-word {
        padding: 18px 0 0 20px;
      }
    }

    &-service {
      padding: 40px 0 0 24px;
      box-shadow: 0px 2px 30px 0px rgba(0, 0, 0, 0.06);
      border-radius: 20px;
      background-color: #fff;
      .wh(380px, 200px);
      box-sizing: border-box;
      margin-right: 30px;
      transition: .2s all linear;
      position: relative;
      border: 1px solid #fff;
      cursor: pointer;
      line-height: 26px;

      &:nth-of-type(3) {
        margin-right: 0;
      }


      > img {
        .wh(120px, 120px);
      }

      &-word {
        .font(14px, #777);
        padding: 18px 0 0 24px;

        > div {
          &:first-child {
            .font(18px, #333);
            padding: 0 0 16px;
          }
        }
      }

      &:hover {
        transform: scale(1.02);
        border: 1px solid rgba(0, 176, 149, 0.5);
      }
    }
  }

  .core-operate {
    padding: 70px 0 0;

    .title_light img {
      .wh(240px, 64px);
    }

    ul {
      padding: 40px 0 71px;
      li {
        display: inline-block;
        .wh(380px, 376px);
        box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.08);
        border-radius: 20px;
        cursor: pointer;
        transition: .2s all linear;

        &:not(:last-child) {
          margin: 0 30px 0 0;
        }

        .bgSrc {
          .wh(380px, 144px);
        }

        .iconSrc {
          .wh(108px, 108px);
          display: block;
          margin: -63px auto 15px;
        }

        &:hover{
          transform: scale(1.02);
        }
      }
    }

    &-title {
      text-align: center;
      .font(18px, #333);
    }

    &-txt {
      padding: 16px 20px 0;
      line-height: 26px;
      text-align: center;
      .font(14px, #777);
    }
  }

  .core-union {
    background-color: #EBF9F7;
    height: 449px;
    padding: 60px 0 0;
    box-sizing: border-box;

    .title_light img {
      .wh(240px, 64px);
    }

    ul {
      padding: 40px 0 0;
      li {
        display: inline-block;
        .wh(380px, 429px);
        vertical-align: top;
        background-color: #fff;
        box-shadow: 0px 2px 30px 0px rgba(0, 0, 0, 0.04);
        border-radius: 20px;
        padding: 40px 33px 0 43px;
        box-sizing: border-box;
        cursor: pointer;
        transition: .2s all linear;

        &:hover {
          transform: scale(1.02);
        }

        &:not(:last-child) {
          margin-right: 30px;
        }

        .title {
          .font(18px, #333);
        }

        .line {
          .wh(25px, 7px);
          background-color: #00B095;
          margin: 16px 0 30px;
        }

        .txt {
          .font(14px, #777);
          line-height: 26px;
        }
      }
    }
  }

  .core-project {
    padding: 0 0 70px;

    .title_light img {
      .wh(361px, 64px);
    }

    .mini {
      .font(16px, #777);
      text-align: center;
    }

    .imgbg {
      margin: 41px auto 0;
      .wh(1108px, 341px);
      display: block;
    }

    ul {
      padding: 20px 0 0 10px;

      li {
        display: inline-block;
        vertical-align: text-top;
        text-align: center;

        &:nth-of-type(1) {
          padding: 0 170px 0 0;
        }

        &:nth-of-type(2) {
          padding: 0 208px 0 0;
        }

        &:nth-of-type(3) {
          padding: 0 180px 0 0;
        }

        .word-article {
          padding: 16px 0 0;
          .wh(149px, auto);
        }
      }
    }

    .change {
      .font(20px, #777);
      padding: 37px 0 0;
      text-align: center;
    }
  }
}
.main1 {
  position: relative;

  .pay-service {
    img {
      .wh(605px, 609px);
      position: absolute;
      left: -55px;
      top: 40px;
    }
  }

  .pay-service-content {
    .wh(624px, auto);
    padding: 85px 0 0;
    text-align: right;

    > span {
      .font(18px, #333);
      padding: 0 0 16px;
      display: block;
    }

    > p {
      .font(14px, #777);
      padding: 0 0 37px;
      line-height: 26px;
    }
  }

  .pay-service-bg {
    background: url('../assets/image/page1/pay-bg.png') no-repeat;
    .wh(313px, 181px);
    background-size: 100% 100%;
    position: absolute;
    right: -103px;
    bottom: 19px;
  }
  
}
</style>
